// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-member-js": () => import("/opt/build/repo/src/templates/member.js" /* webpackChunkName: "component---src-templates-member-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-event-js": () => import("/opt/build/repo/src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-datenschutz-js": () => import("/opt/build/repo/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressionen-js": () => import("/opt/build/repo/src/pages/impressionen.js" /* webpackChunkName: "component---src-pages-impressionen-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-team-js": () => import("/opt/build/repo/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-ueber-uns-js": () => import("/opt/build/repo/src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-veranstaltungen-js": () => import("/opt/build/repo/src/pages/veranstaltungen.js" /* webpackChunkName: "component---src-pages-veranstaltungen-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

